.socialMediaLinks
  align-items: center
  display: flex
  flex-direction: column

  a
    align-items: center
    display: flex
    border: 1px solid rgba(#000, .25)
    border-radius: 8px
    color: #000
    font-size: 1.1rem
    height: 3em
    justify-content: center
    margin-bottom: 1em
    max-width: 95%
    padding: .25em 1em
    position: relative
    width: 200px

    svg,
    span
      z-index: 1

    span
      margin-left: .5em

    &:global(.etsy)
      svg
        font-size: 2em
        color: #f1641e

    &:global(.insta)
      svg
        color: #d62976

    :global(.image)
      background-image: url('~assets/images/bg_splash_smartobject_500x422.png')
      background-position: 50% 60%
      background-repeat: no-repeat
      background-size: cover
      bottom: 0
      left: 0
      opacity: .25
      position: absolute
      right: 0
      top: 0
      z-index: 0

    &:hover
      :global(.image)
          opacity: .5

